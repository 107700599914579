import Vue from "vue";
import App from "./App.vue";
import ApiService from "./services/api.service";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "@/assets/scss/main.scss";

import "@libs";
import "@/mixins/general";
import "./filters";

Vue.config.productionTip = false;
const configUrl = `${window.location.origin}/config.json`;

Vue.axios.get(configUrl).then(resp => {
  ApiService.init(resp.data.baseUrl);

  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount("#app");
});
