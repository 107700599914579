<template>
  <footer>
    <b-container>
      <b-row>
        <b-col>
          <p>Copyright © {{ year }} <br />Version {{ packageVersion }} <br />Last update: October 20, 2023 <br />By PaL4pa En61Ne</p>
        </b-col>
      </b-row>
    </b-container>
  </footer>
</template>

<script>
export default {
  name: "FooterSection",
  components: {},
  data() {
    return {
      year: null,
      packageVersion: `2.00.01 | 774938`
    };
  },
  computed: {},
  mounted() {
    this.year = new Date().getFullYear();
  }
};
</script>
