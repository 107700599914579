// Authorization
export const LOGIN = "login";
export const SIGNUP = "signUp";
export const LOGOUT = "logout";
export const FETCH_USER = "fetchUser";

// SCHEDULE
export const FETCH_SCHEDULE = "fetchSchedule";
export const REMOVE_SCHEDULE = "removeSchedule";

// Loader

export const CHANGE_LOADER = "changeLoader";
