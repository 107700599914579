<template>
  <b-container>
    <vue-element-loading :active="loader" spinner="bar-fade-scale" :color="loaderConfig.color" :background-color="loaderConfig.backgroundColor" />
    <b-row class="login-container justify-content-start">
      <b-col>
        <b-form @submit.prevent="onRegister" v-if="show" class="text-light">
          <b-form-group id="form-register">
            <label for="username">Username</label>
            <b-form-input id="username" v-model="form.username" type="text" placeholder="Enter Username" required class="mb-2"></b-form-input>
            <label for="telegramId">Telegram</label>
            <b-form-input id="telegramId" v-model="form.telegramId" type="text" placeholder="Enter ID Telegram" required class="mb-2"></b-form-input>
            <label for="phone">Whatsapp</label>
            <b-form-input id="phone" v-model="form.phone" type="number" placeholder="Whatsapp Number" required class="mb-2"></b-form-input>
            <label for="password">Password</label>
            <b-form-input id="password" v-model="form.password" type="password" placeholder="Password" required class="mb-2"></b-form-input>
            <label for="registerPasswordRepeat">Repeat Password</label>
            <b-form-input
              id="registerPasswordRepeat"
              v-model="form.registerPasswordRepeat"
              type="password"
              placeholder="Repeat Password"
              required
            ></b-form-input>
          </b-form-group>
          <b-form-checkbox
            id="regsiterTermsCheck"
            v-model="form.regsiterTermsCheck"
            wrapperClass="d-flex justify-content-center mb-4"
            class="mb-4 mt-2 ms-2"
          >
            <small class="text-light ms-2">Saya telah setuju dengan ketentuan yang berlaku</small>
          </b-form-checkbox>

          <div class="d-flex justify-content-center">
            <b-button type="submit" block class="mb-1"> Daftar </b-button>
          </div>
        </b-form>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { SIGNUP } from "@actions";
export default {
  name: "SignupView",

  data() {
    return {
      form: {
        username: "",
        telegramId: "",
        phone: "",
        password: "",
        registerPasswordRepeat: "",
        regsiterTermsCheck: ""
      },
      show: true,
      loader: false
    };
  },

  methods: {
    async onRegister(event) {
      event.preventDefault();
      this.loader = true;
      try {
        if (this.form.password != this.form.registerPasswordRepeat) {
          alert("Oops! Passwords do not match. Please make sure both passwords are the samke.");
          return;
        } else {
          await this.$store.dispatch(SIGNUP, this.form);
          this.$router.go(this.$router.currentRoute);
        }
      } catch (error) {
        alert(error.message);
        // this.showToast(`error`, error);
      } finally {
        this.loader = false;
      }
    }
  }
};
</script>
