<template>
  <header class="header-area header-sticky">
    <b-container>
      <b-row class="justify-content-center">
        <b-col lg="8" class="text-center mt-1 mb-2">
          <nav class="main-nav">
            <img src="@/assets/images/logo.png" alt="Palapa Engine" />

            <ul class="nav colaps">
              <li><router-link :to="{ name: `home` }">Home</router-link></li>
              <li><router-link :to="{ name: `donate` }">Donate</router-link></li>
              <li><router-link :to="{ name: `faq` }">FaQ</router-link></li>
              <li><router-link :to="{ name: `log` }">Change Log</router-link></li>
              <li v-if="isAuthenticated" @click="logout"><router-link :to="{ name: `log` }">Sign Out</router-link></li>
              <li v-else><a @click="modal.signIn = !modal.signIn">Sign In</a></li>
            </ul>
            <b-button v-b-toggle.navbarCollapse class="menu-trigger">
              <span>menu</span>
            </b-button>
            <!-- mobile menu -->

            <b-sidebar title="Palapa Engine" id="navbarCollapse" class="sidebar" shadow>
              <ul>
                <li><router-link :to="{ name: `home` }">Home</router-link></li>
                <li><router-link :to="{ name: `donate` }">Donate</router-link></li>
                <li><router-link :to="{ name: `faq` }">FaQ</router-link></li>
                <li><router-link :to="{ name: `log` }">Change Log</router-link></li>
                <li v-if="isAuthenticated" @click="logout"><router-link :to="{ name: `log` }">Sign Out</router-link></li>
                <li v-else><a @click="modal.signIn = !modal.signIn">Sign In</a></li>
              </ul>
            </b-sidebar>
          </nav>
        </b-col>
      </b-row>
      <b-modal v-model="modal.signIn" title="LOGIN" hide-footer centered size="md" lazy>
        <sign-in />
      </b-modal>
    </b-container>
  </header>
</template>

<script>
import { mapGetters } from "vuex";
import { LOGOUT } from "@actions";
import SignIn from "@/components/account/SignIn";
export default {
  name: "SignupView",
  components: {
    SignIn
  },
  data() {
    return {
      modal: { signIn: false }
    };
  },
  computed: {
    ...mapGetters(["isAuthenticated"])
  },
  methods: {
    logout() {
      this.$store.dispatch(LOGOUT);
      this.$router.go(this.$router.currentRoute);
    }
  }
};
</script>
