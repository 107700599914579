import { SET_USER, SET_TOKEN } from "@mutations";

export default {
  [SET_USER](state, payload) {
    state.user = payload;
  },
  [SET_TOKEN](state, payload) {
    state.token = payload;
  }
};
