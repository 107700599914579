<template>
  <b-container>
    <vue-element-loading :active="loader" spinner="bar-fade-scale" :color="loaderConfig.color" :background-color="loaderConfig.backgroundColor" />
    <b-row class="login-container justify-content-start">
      <b-col>
        <b-form @submit="onLogin" v-if="show" class="text-light">
          <b-form-group id="form-login">
            <label for="username" class="mb-1">Username</label>
            <b-form-input id="username" v-model="form.username" type="text" placeholder="Masukan Username" required class="mb-2"></b-form-input>
            <label for="password" class="mb-1">Password</label>
            <b-form-input id="password" v-model="form.password" type="password" placeholder="Masukan Password" required></b-form-input>
          </b-form-group>

          <b-row class="mt-2 mb-4">
            <b-col class="d-flex justify-content-start ms-2">
              <b-form-checkbox v-model="form.rememberMe" id="rememberMe"><small class="ms-2">Remember me</small></b-form-checkbox>
            </b-col>

            <!-- <b-col class="d-flex justify-content-end">
              <router-link :to="{ name: `forgot` }"><small>Lupa password?</small></router-link>
            </b-col> -->
          </b-row>
          <div class="d-flex justify-content-center">
            <b-button type="submit" block class="mb-1"> Masuk </b-button>
          </div>
          <div class="d-flex justify-content-center mt-2">
            <small>Belum punya akun ? <span style="color: #0f0" @click="modal.signUp = !modal.signUp">Daftar</span></small>
          </div>
        </b-form>
      </b-col>
    </b-row>

    <b-modal v-model="modal.signUp" title="CREATE ACCOUNT" hide-footer centered size="md" lazy>
      <sign-up />
    </b-modal>
  </b-container>
</template>
<script>
import { LOGIN } from "@actions";
import SignUp from "@/components/account/Signup.vue";

export default {
  name: "LoginView",
  components: {
    SignUp
  },
  data() {
    return {
      form: {
        username: "",
        password: "",
        rememberMe: false
      },
      show: true,
      loader: false,
      modal: { signUp: false }
    };
  },

  methods: {
    async onLogin(event) {
      event.preventDefault();
      this.loader = true;
      try {
        await this.$store.dispatch(LOGIN, this.form);
        this.$router.go(this.$router.currentRoute);
      } catch (error) {
        alert(error.message);
        // this.showToast(`error`, error);
      } finally {
        this.loader = false;
      }
    }
  }
};
</script>
