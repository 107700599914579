<template>
  <div id="app">
    <BaseLayout>
      <router-view />
    </BaseLayout>
  </div>
</template>

<script>
import BaseLayout from "@/layouts/BaseLayout.vue";
import { FETCH_SCHEDULE } from "@actions";

export default {
  name: "App",
  components: {
    BaseLayout
  },
  timers: {
    fetchData: { time: 60000, autostart: true, repeat: true }
  },
  mounted() {
    this.fetchData();
  },

  methods: {
    fetchData() {
      // console.log(`reload`);
      this.$store.dispatch(FETCH_SCHEDULE);
    }
  }
};
</script>
