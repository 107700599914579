import { FETCH_SCHEDULE, REMOVE_SCHEDULE } from "@store/constants/actions.type";
import { SET_SCHEDULE, REMOVE_SCHEDULE_FROM_COLLECTION } from "@store/constants/mutations.type";

import api from "@api";

export default {
  async [FETCH_SCHEDULE](context) {
    try {
      let result = await api.get("schedule");
      context.commit(SET_SCHEDULE, result);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [REMOVE_SCHEDULE](context, scheduleCode) {
    try {
      context.commit(REMOVE_SCHEDULE_FROM_COLLECTION, scheduleCode);
    } catch (error) {
      throw new Error(error);
    }
  }
};
