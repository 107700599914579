import { LOGIN, LOGOUT, FETCH_USER, SIGNUP } from "@actions";
import { SET_USER, SET_TOKEN } from "@mutations";
import ApiService from "@api";
import { storeToken, clearToken } from "@/services/storage.service";
import router from "@/router";

export default {
  async [LOGIN](context, payload) {
    try {
      let resp = await ApiService.post("auth/token", payload);
      storeToken(resp.jwtToken);
      context.commit(SET_TOKEN, resp.jwtToken);
      ApiService.setHeader(resp.jwtToken);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [SIGNUP](context, payload) {
    try {
      let resp = await ApiService.post("auth/signup", payload);
      storeToken(resp.jwtToken);
      context.commit(SET_TOKEN, resp.jwtToken);
      ApiService.setHeader(resp.jwtToken);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [LOGOUT](context) {
    ApiService.post("auth/revoketoken");
    clearToken();
    context.commit(SET_TOKEN, null);
    ApiService.removeHeader();
    context.commit(SET_USER, null);
  },
  async [FETCH_USER](context) {
    try {
      // console.log(context);
      // let resp = await ApiService.get("me");
      // if (!resp) throw new Error("No user found");
      // if (resp.role === 1) {
      //   resp.agentList = await ApiService.get("agent");
      // }
      // context.commit(SET_USER, resp);
    } catch (error) {
      clearToken();
      context.commit(SET_TOKEN, null);
      ApiService.removeHeader();
      context.commit(SET_USER, null);
      router.push({ name: `login` });
      throw new Error(error);
    }
  }
};
