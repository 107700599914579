import Vue from "vue";
import VueRouter from "vue-router";

import General from "./routes/general";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    ...General
  ]
});

router.beforeEach((to, from, next) => {

  // if (to.name !== "signin") {
  //   // TARGET ROUTE IS OTHER THAN LOGIN
  //   if (getToken() == null) router.push({ name: "signin" });
  // } else if (to.name !== "signup") {
  //   // TARGET ROUTE IS LOGIN
  //   if (getToken() != null) router.push({ name: "signup" });
  // }else {
  //   // TARGET ROUTE IS LOGIN
  //   if (getToken() != null) router.push({ name: "home" });
  // }
  next();
});

export default router;
