export default [
    {
      path: "/",
      name: "home",
      component: () => import("@/views/Home.vue")
    },
    {
      path: "/donate",
      name: "donate",
      component: () => import("@/views/Donate.vue")
    },
    {
      path: "/faq",
      name: "faq",
      component: () => import("@/views/Faq.vue")
    },
    {
      path: "/log",
      name: "log",
      component: () => import("@/views/Log.vue")
    },
    {
      path: "/forgot",
      name: "forgot",
      component: () => import("@/views/Forgot.vue")
    },
    {
      path: '/:pathMatch(.*)*',
      redirect: "/",
    }
  ];
  