import { SET_SCHEDULE, REMOVE_SCHEDULE_FROM_COLLECTION } from "@store/constants/mutations.type";

export default {
  [SET_SCHEDULE](state, payload) {
    state.scheduleList = payload;
  },
  [REMOVE_SCHEDULE_FROM_COLLECTION](state, scheduleCode) {
    let idx = state.scheduleList.findIndex(x => x.code == scheduleCode);
    state.scheduleList.splice(idx, 1);
  }
};
