//AUTHORIZATION
export const SET_AUTH = "setAuth";
export const REMOVE_AUTH = "removeAuth";
export const SET_USER = "setUser";
export const SET_TOKEN = "setToken";

// SCHEDULE
export const SET_SCHEDULE = "setSchedule";
export const REMOVE_SCHEDULE_FROM_COLLECTION = "removeScheduleFromCollection";

//LOADER
export const SET_LOADER = "setLoader";
