import axios from "axios";

const LOCAL_STORAGE_TOKEN = "midas.admin.token";

export const storeToken = token => {
  localStorage.setItem(LOCAL_STORAGE_TOKEN, token);
};

export const clearToken = () => {
  localStorage.removeItem(LOCAL_STORAGE_TOKEN);
};

export const getToken = () => {
  var token = localStorage.getItem(LOCAL_STORAGE_TOKEN);
  return token ?? null;
};

export const getNewToken = () => {
  return new Promise((resolve, reject) => {
    axios
      .post("auth/refreshtoken")
      .then(response => {
        storeToken(response.jwtToken);
        resolve(response.jwtToken);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export default {
  getNewToken,
  storeToken,
  clearToken,
  getToken
};
