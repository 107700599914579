<template>
  <div class="background">
    <div class="background-sec">
      <header-section />
      <div class="h-700">
        <div class="mt-body">
          <router-view />
        </div>
      </div>
      <footer-section />
    </div>
  </div>
</template>

<script>
import HeaderSection from "./components/HeaderSection.vue";
import FooterSection from "./components/FooterSection.vue";
export default {
  name: `BaseLayout`,
  components: { HeaderSection, FooterSection },
};
</script>
