import Vue from "vue";

Vue.mixin({
  data() {
    return {
      vueNumericConfig: {
        minus: true,
        precision: 2
      },
      loaderConfig: {
        color: "#33f15c",
        backgroundColor: "#000000"
      }
    };
  },
  methods: {
    updateCollection(collection, keyCol, newRecord) {
      let idx = collection.findIndex(x => x[keyCol] == newRecord[keyCol]);
      collection[idx] = newRecord;
      return collection;
    },
    updateCollectionByIdx(collection, idx, newRecord) {
      collection[idx] = newRecord;
      return collection;
    },
    removeFromCollection(collection, keyCol, keyValue) {
      let idx = collection.findIndex(x => x[keyCol] == keyValue);
      collection.splice(idx, 1);
    },
    clearObject(obj) {
      for (var p in obj) {
        obj[p] = null;
      }
    },
    download(stream, filename) {
      var fileURL = window.URL.createObjectURL(new Blob([stream]));
      var fileLink = document.createElement("a");
      fileLink.href = fileURL;
      fileLink.setAttribute("download", filename);
      document.body.appendChild(fileLink);
      fileLink.click();
    },
    successCopy() {
      this.showToast(`success`, `Copied to clipboard!`);
    },
    pascalCase(str) {
      return str.toString().replace(/\w+/g, function (w) {
        return w[0].toUpperCase() + w.slice(1).toLowerCase();
      });
    },
    timerClass(timeLeft) {
      if (timeLeft > 10 * 60) return `text-success`;
      else if (timeLeft > 5 * 60) return `text-warning`;
      else return `text-danger`;
    }
  }
});
